// import i1 from "../media/vid/thumbnail_1.jpg";
// import i2 from "../media/vid/thumbnail_2.jpg";
// import i3 from "../media/vid/thumbnail_3.jpg";
// import i4 from "../media/vid/thumbnail_4.jpg";
// import i5 from "../media/vid/thumbnail_5.jpg";
// import i6 from "../media/vid/thumbnail_6.jpg";
// import i7 from "../media/vid/thumbnail_7.jpg";
import i1 from "../media/vid/thumbnail_1.webp";
import i2 from "../media/vid/thumbnail_2.webp";
import i3 from "../media/vid/thumbnail_3.webp";
import i4 from "../media/vid/thumbnail_4.webp";
import i5 from "../media/vid/thumbnail_5.webp";
import i6 from "../media/vid/thumbnail_6.webp";
import i7 from "../media/vid/thumbnail_7.webp";

import v1h265 from "../media/vid/clip_1.mp4";
import v2h265 from "../media/vid/clip_2.mp4";
import v3h265 from "../media/vid/clip_3.mp4";
import v4h265 from "../media/vid/clip_4.mp4";
import v5h265 from "../media/vid/clip_5.mp4";
import v6h265 from "../media/vid/clip_6.mp4";
import v7h265 from "../media/vid/clip_7.mp4";
import v1h265a from "../media/vid/very_small_files/clip_1.m4v";
import v2h265a from "../media/vid/very_small_files/clip_2.m4v";
import v3h265a from "../media/vid/very_small_files/clip_3.m4v";
import v4h265a from "../media/vid/very_small_files/clip_4.m4v";
import v5h265a from "../media/vid/very_small_files/clip_5.m4v";
import v6h265a from "../media/vid/very_small_files/clip_6.m4v";
import v7h265a from "../media/vid/very_small_files/clip_7.m4v";
import v1h265b from "../media/vid/very_small_files/Clip 1-1.mp4";
import v2h265b from "../media/vid/very_small_files/Clip 2-2.mp4";
import v3h265b from "../media/vid/very_small_files/Clip 3-3.mp4";
import v4h265b from "../media/vid/very_small_files/Clip 4-4.mp4";
import v5h265b from "../media/vid/very_small_files/Clip 5-5.mp4";
import v6h265b from "../media/vid/very_small_files/Clip 6-6.mp4";
import v7h265b from "../media/vid/very_small_files/Clip 7-7.mp4";
import v1h265c from "../media/vid/raw/Clip_1.m4v";
import v2h265c from "../media/vid/raw/Clip_2.m4v";
import v3h265c from "../media/vid/raw/Clip_3.m4v";
import v4h265c from "../media/vid/raw/Clip_4.m4v";
import v5h265c from "../media/vid/raw/Clip_5.m4v";
import v6h265c from "../media/vid/raw/Clip_6.m4v";
import v7h265c from "../media/vid/raw/Clip_7.m4v";
import v1wm from "../media/vid/clip_1.webm";
import v2wm from "../media/vid/clip_2.webm";
import v3wm from "../media/vid/clip_3.webm";
import v4wm from "../media/vid/clip_4.webm";
import v5wm from "../media/vid/clip_5.webm";
import v6wm from "../media/vid/clip_6.webm";
import v7wm from "../media/vid/clip_7.webm";

const youtube = "https://youtu.be/p8eM7RrDK-Q";
// const v1 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_1.m4v?raw=true";
// const v2 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_2.m4v?raw=true";
// const v3 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_3.m4v?raw=true";
// const v4 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_4.m4v?raw=true";
// const v5 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_5.m4v?raw=true";
// const v6 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_6.m4v?raw=true";
// const v7 =
//   "https://github.com/captain-inch/lcc_export/blob/main/src/media/vid/Clip_7.m4v?raw=true";

export const bgvideos = [
  [
    [
      { src: v1wm, type: "video/webm" },
      { src: v1h265, type: "video/mp4" },
    ],
    i1,
  ],
  [
    [
      { src: v2wm, type: "video/webm" },
      { src: v2h265, type: "video/mp4" },
    ],
    i2,
  ],
  [
    [
      { src: v3wm, type: "video/webm" },
      { src: v3h265, type: "video/mp4" },
    ],
    i3,
  ],
  [
    [
      { src: v4wm, type: "video/webm" },
      { src: v4h265, type: "video/mp4" },
    ],
    i4,
  ],
  [
    [
      { src: v5wm, type: "video/webm" },
      { src: v5h265, type: "video/mp4" },
    ],

    i5,
  ],
  [
    [
      { src: v6wm, type: "video/webm" },
      { src: v6h265, type: "video/mp4" },
    ],
    i6,
  ],
  [
    [
      { src: v7wm, type: "video/webm" },
      { src: v7h265, type: "video/mp4" },
    ],
    i7,
  ],
];

export const bgvideosa = [
  [[{ src: v1h265c, type: "video/mp4" }], i1],
  [[{ src: v2h265c, type: "video/mp4" }], i2],
  [[{ src: v3h265c, type: "video/mp4" }], i3],
  [[{ src: v4h265c, type: "video/mp4" }], i4],
  [[{ src: v5h265c, type: "video/mp4" }], i5],
  [[{ src: v6h265c, type: "video/mp4" }], i6],
  [[{ src: v7h265c, type: "video/mp4" }], i7],
];

export const fullvideoURL = youtube;
// export const localVideoTest = vlocal;
// export const bgvideos = [
//   [vlocal, i1],
//   [vlocal, i2],
//   [vlocal, i3],
// ];
